<template>
  <div>
    <standbyDialog :localLoadingDialog="localLoadingDialog"></standbyDialog>
    <a
      class="mr-4"
      href="javascript:void(0);"
      @click="getZippedReports('getZippedReports')"
    >
      <v-icon
        :title="language.componentLabel.titlelDR"
        style="margin-right: 7px !important"
      >
        mdi-folder-zip
      </v-icon>
      <span
        style="position: relative; top: 3px; left: 1px"
        v-if="$vuetify.breakpoint.mdAndUp"
        >{{ language.componentLabel.labelDR }}</span
      ></a
    >
    <a
      href="javascript:void(0);"
      @click="getZippedReports('getZippedSimpleReports')"
    >
      <v-icon :title="language.componentLabel.titlelOVR" class="mr-1">
        mdi-folder-zip-outline
      </v-icon>
      <span
        style="position: relative; top: 3px"
        v-if="$vuetify.breakpoint.mdAndUp"
        >{{ language.componentLabel.labelOVR }}</span
      ></a
    >
  </div>
</template>
<script>
import standbyDialog from "@/commonComponents/standbyDialog.vue";
export default {
  components: { standbyDialog },
  props: ["result", "additional"],
  data() {
    return { localLoadingDialog: false };
  },
  methods: {
    getZippedReports(type) {
      this.localLoadingDialog = true;
      let selected = [];
      Object.keys(this.additional.selectedRows).forEach(function eachKey(key) {
        selected.push(key);
      });
      if (selected.length > 0) {
        this.$emit("show-warning", false);
        var URL = "serve.php?f=testing&f2=testInfoPdf";
        if (type == "getZippedSimpleReports") {
          URL = "serve.php?f=testing&f2=testInfoPdfSimple";
        }
        let caller = this;
        this.frameworkAxiosRequest({
          method: 'POST',
          url: URL,
          dataType: 'json',
          data: {
            function: type,
            responseType: 'ajax',
            selected: selected,
          },
        })
            .then((response) => {
            //console.log(response.data);
            //  var filePath = response.data.result.json["redirect"];
            this.localLoadingDialog = false;
            // window.location.href = filePath;
            if (
                typeof response.data.userInfo.loggedIn != "undefined" &&
                response.data.userInfo.loggedIn == true
            ) {
              if (typeof response.data.result.json["redirect"] != "undefined") {
                window.location.href = response.data.result.json["redirect"];
              }
            } else {
              caller.$notify({
                group: "foo",
                text: "Your session has timed out. Please try again after re-login.",
                duration: 3000,
                type: "warning",
                speed: 600,
              });
              setTimeout(function () {
                window.location.href = "";
              }, 3000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.localLoadingDialog = false;
        this.$emit("show-warning", true);
      }
    },
  },
};
</script>